import Image from 'next/image';

import type { ILogoProps } from './Logo.types';

const Logo: React.FC<ILogoProps> = ({ logo }) => {
  if (!logo) return null;

  return <Image src={logo} alt={`${logo} logo`} loading="lazy" fill />;
};

export default Logo;
