import Image from 'next/image';

import styles from './Tag.module.css';
import type { TagProps } from './Tag.types';

const Tag: React.FC<TagProps> = ({ isBig, text, icon, bgColor, children }) => {
  const fontStyle = isBig ? { fontSize: '15px' } : undefined;
  const iconStyle = isBig ? { width: '13px', height: '13px' } : undefined;
  return (
    <div className={styles.container} style={{ background: bgColor }}>
      {children}
      <Image style={iconStyle} src={icon} alt="tag icon" width={13} height={13} />
      <span style={fontStyle} className={styles.text}>
        {text}
      </span>
    </div>
  );
};

export default Tag;
