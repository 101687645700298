import Image from 'next/image';
import type { FC } from 'react';

import circle from '@/public/assets/icons/circle.svg';
import circleFill from '@/public/assets/icons/circle-filled.svg';

import styles from './accordionOption.module.css';
import type { AccordionOptionProps } from './AccordionOption.types';

const AccordionOption: FC<AccordionOptionProps> = ({
  name,
  title,
  isSelected,
  buttonCallback,
  keyDownCallback,
}) => {
  const icon = isSelected ? circleFill : circle;
  const color = isSelected ? 'var(--charcoal-gray)' : 'var(--gray)';
  return (
    <div className={styles.accordionOption__container}>
      <button
        type="button"
        onClick={() => buttonCallback(title, name)}
        onKeyDown={(event) => keyDownCallback(event, 'filter')}
      >
        <Image src={icon} alt="selection circle" width={16} height={16} />
      </button>
      <span style={{ color }}>{name}</span>
    </div>
  );
};
export default AccordionOption;
