import type { ICompanyName } from './CompanyName.types';

const CompanyName: React.FC<ICompanyName> = ({ name, lastAdded }) => {
  return (
    <>
      {name && <p>{name}</p>}
      {lastAdded && <span>נוספו לאחרונה</span>}
    </>
  );
};

export default CompanyName;
