'use client';

import Link from 'next/link';
import React from 'react';
import ReactGA from 'react-ga4';

import Ribbon from './components/Ribbon/Ribbon';
import styles from './Product.module.css';
import type { ProductProps } from './Product.types';

const Product: React.FC<ProductProps> = ({ id, lastAdded, children, name }) => {
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // prevent bubbling
    e.stopPropagation();
    ReactGA.event({
      category: 'Button Click',
      action: 'click_on_product_card_cta',
      label: `Product Name: ${name}`,
    });
  };
  return (
    <Link onClick={handleLinkClick} className={styles.link} href={`/product/${id}`}>
      {lastAdded && <Ribbon />}
      <div className={styles.container}>{children}</div>
    </Link>
  );
};

export default Product;
