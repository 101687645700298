import parse from 'html-react-parser';
import Image from 'next/image';

import quote from '@/public/assets/icons/quote.svg';

import styles from './Quote.module.css';
import type { IQuoteProps } from './Quote.types';

const Quote: React.FC<IQuoteProps> = ({ text }) => {
  return (
    <>
      <Image
        className={styles.quoteIcon}
        src={quote}
        alt="quote"
        loading="lazy"
        width={14}
        height={10}
      />
      {text && <div className={styles.text}>{parse(text)}</div>}
    </>
  );
};

export default Quote;
