import Image from 'next/image';
import type { FC } from 'react';

import searchIcon from '@/public/assets/icons/search.svg';

import styles from './searchButton.module.css';
import type { SearchButtonProps } from './SearchButton.types';

const SearchButton: FC<SearchButtonProps> = ({ callback }) => {
  return (
    <button type="submit" className={styles.button} onClick={callback}>
      <Image src={searchIcon} alt="search" width={30} height={30} />
    </button>
  );
};
export default SearchButton;
