import Image from 'next/image';

import stars from '@/public/assets/icons/stars.svg';

import styles from './Ribbon.module.css';

const Ribbon = () => {
  return (
    <div className={styles.ribbon}>
      <Image loading="lazy" src={stars} alt="stars" width={16} height={16} />
      <span className={styles.text}>חדש בHub</span>
    </div>
  );
};

export default Ribbon;
