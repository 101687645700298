'use client';

import { useRouter } from 'next/navigation';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import Accordion from '@/components/Accordion/Accordion';
import AccordionOption from '@/components/AccordionOption/AccordionOption';
import FilterLayout from '@/components/FilterLayout/FilterLayout';

import SearchButton from './_components/SearchButton/SearchButton';
import { accordionItems } from './constants';
import styles from './SearchFilterBar.module.css';
import type { SearchFilterBarProps } from './SearchFilterBar.types';

const SearchFilterBar: FC<SearchFilterBarProps> = ({
  filters,
  query,
  children,
  showFullSearch = true,
}) => {
  const router = useRouter();
  const [filtersSelected, setFiltersSelected] = useState<string[]>([]);
  const [openItem, setOpenItem] = useState<string | null>(null);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (filters) {
      setFiltersSelected(filters);
    }
    if (query) {
      setSearch(query);
    }
  }, [filters, query]);

  const handleAccordionChange = (itemValue: string) => {
    setOpenItem(openItem === itemValue ? null : itemValue);
  };

  const rulesForFilters: any = {
    סוג: { maxSelections: 1 },
    מגזר: { maxSelections: 2 },
  };
  const handleFilterChange = (title: string, name: string) => {
    const filterKey = `${title}:${name}`;
    const rule = rulesForFilters[title];
    const maxSelections = rule?.maxSelections ?? Infinity;
    const currentSelections = filtersSelected.filter((item) =>
      item.startsWith(`${title}:`),
    );

    if (filtersSelected.includes(filterKey)) {
      setFiltersSelected(filtersSelected.filter((item) => item !== filterKey));
    } else if (currentSelections.length < maxSelections) {
      setFiltersSelected((prev) => [...prev, filterKey]);
    }
  };

  const searchByFilter = () => {
    setSearch('');
    setOpenItem(null);
    const queryParams = filtersSelected
      .map((filter) => {
        const [title, name] = filter.split(':');
        if (!name) return title;
        return `${title}=${encodeURIComponent(name)}`;
      })
      .join('&');

    router.replace(`/search?${queryParams}`);
  };

  const searchByInput = () => {
    const url = search?.length
      ? `/search?query=${encodeURIComponent(search)}`
      : '/search';
    router.push(url);
  };

  const handleKeyDown = (event: any, searchContext: 'input' | 'filter') => {
    if (event.key === 'Enter') {
      if (searchContext === 'input') {
        searchByInput();
      } else if (searchContext === 'filter') {
        searchByFilter();
      }
    }
  };
  return (
    <>
      <div className={styles.filterContainer}>
        <FilterLayout title="חיפוש לפי סינון">
          <div className={styles.wrapper}>
            {accordionItems.map((item) => (
              <Accordion
                openItem={openItem}
                handleAccordionChange={handleAccordionChange}
                key={item.title}
                title={item.title}
              >
                {item.options.map((option) => {
                  const filterValue = `${item.title}:${option}`;

                  const isSelected = filtersSelected.includes(filterValue);
                  return (
                    <AccordionOption
                      key={option}
                      name={option}
                      title={item.title}
                      isSelected={isSelected}
                      buttonCallback={handleFilterChange}
                      keyDownCallback={handleKeyDown}
                    />
                  );
                })}
              </Accordion>
            ))}
            <SearchButton callback={searchByFilter} />
          </div>
        </FilterLayout>
      </div>
      <div className={`${styles.filterContainer}  ${!showFullSearch ? styles.hide : ''}`}>
        <FilterLayout title="חיפוש לפי הנחיה">
          <div className={styles.wrapper}>
            <input
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
              value={search}
              type="text"
              className={styles.input}
              placeholder="חיפוש לפי שם או תיאור"
              onKeyDown={(event) => handleKeyDown(event, 'input')} // Add this line
            />
            <SearchButton callback={searchByInput} />
          </div>
        </FilterLayout>
      </div>
      {children}
    </>
  );
};

export default SearchFilterBar;
