import './styles.css';

import type { AccordionTriggerProps } from '@radix-ui/react-accordion';
import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import type { AccordionProps } from './Accordian.types';

interface AccordionTriggerExtendedProps extends AccordionTriggerProps {
  className?: string;
  children: React.ReactNode;
}

const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  AccordionTriggerExtendedProps
>(function AccordionTrigger({ children, className, ...props }, forwardedRef) {
  return (
    <Accordion.Header className="AccordionHeader">
      <Accordion.Trigger
        className={classNames('AccordionTrigger', className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <ChevronDownIcon className="AccordionChevron" aria-hidden />
      </Accordion.Trigger>
    </Accordion.Header>
  );
});

AccordionTrigger.displayName = 'AccordionTrigger';

interface AccordionContentProps {
  children: React.ReactNode;
  className?: string;
}

const AccordionContent = React.forwardRef<HTMLDivElement, AccordionContentProps>(
  function AccordionContent({ children, className, ...props }, forwardedRef) {
    return (
      <Accordion.Content
        className={classNames('AccordionContent', className)}
        {...props}
        ref={forwardedRef}
      >
        <div className="AccordionContentText">
          <hr />
          <div className="AccordionContentTextInner">{children}</div>
        </div>
      </Accordion.Content>
    );
  },
);

AccordionContent.displayName = 'AccordionContent';

const AccordionContainer: FC<AccordionProps> = ({
  title,
  openItem,
  handleAccordionChange,
  children,
}) => {
  return (
    <Accordion.Root
      className="AccordionRoot"
      type="single"
      value={openItem}
      onValueChange={handleAccordionChange}
      collapsible
    >
      <Accordion.Item className="AccordionItem" value={title}>
        <AccordionTrigger>{title}</AccordionTrigger>
        <AccordionContent>{children}</AccordionContent>
      </Accordion.Item>
    </Accordion.Root>
  );
};

AccordionContainer.displayName = 'AccordionContainer';

export default AccordionContainer;
